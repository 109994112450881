//push variables to datalayer
export const pageview = (url, locale) => {
  console.log(`App is changing to ${document.location.pathname}`);
  setTimeout(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "virtualPageview",
      pagePath: document.location.pathname || url,
      pageTitle: document?.title,
      pageLanguage: locale,
      pageUrl: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
    });
  }, 2000);
};
