import {
  START_FETCH_ALL_TEMPLATE_2_DATA,
  SUCCESS_FETCH_ALL_TEMPLATE_2__DATA,
  ERROR_FETCH_ALL_TEMPLATE_2__DATA,
} from "./../actions/allTemplate2sActionsTypes";

const initialState = {
  isLoading: false,
  template2Data: [],
  errorFetchingTemplatesData: "",
};

const AllTemplate2sReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_ALL_TEMPLATE_2_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_ALL_TEMPLATE_2__DATA:
      return {
        ...state,
        isLoading: false,
        template2Data: action.template2Data,
        errorFetchingTemplatesData: "",
      };
    case ERROR_FETCH_ALL_TEMPLATE_2__DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingTemplatesData: action.error,
      };
    default:
      return state;
  }
};
export default AllTemplate2sReducer;
