import { combineReducers } from "redux";

// Import all required reducer
import home from "./home";
import labelsConfig from "./labelsAndConfig";
import allTemplate2s from "./allTemplate2s";
import restaurantMenu from "./restaurantMenu";
import landingPage from "./landingPage";

const rootReducers = combineReducers({
  homePage: home,
  labelsConfig,
  allTemplate2s,
  restaurantMenu,
  landingPage,
});

export default rootReducers;
