// GraphQL query build for Labels and Configs data
export const LABEL_AND_CONFIG_QUERY = `{
  labelsList(locale: lang) {
    contactUs
    bookATable
    reserveTerrace
    followUsOnInstagram
    backTo
    signUp
    signUpAndDownload
    name
    surname
    email
    numberOfPeople
    dateOfBirth
    keepMeUpdated
    getYourCoupon
    download
    menu
  }
  
  siteConfig (locale: lang) {
    headerLogo {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    footerLogo {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    partnerLogo{
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    farmersLogo{
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    michelinLogo{
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    logoIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    logoIconWhite {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    wideBackgroundImage {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    rightCircleImage {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    leftCircleImage {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    instagram
    instagramIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    twitter
    twitterIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    facebook
    facebookIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    downloadIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    reserveATableLink {
      title
      slug
    }
    reserveATableMixologyLink {
      slug
    }
    footerEmail
    footerPhone
    footerCallUsTitle
    privacyPolicy
    cookiePolicy
    cookieSettings
    errorTitle
    errorDescription
    errorBackground {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    missingPageTitle
    missingPageDescription
    missingPageBackground {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    businessLunchBackground {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    headerMenu {
      ... on HomeRecord {
        title        
      }
      ... on EventsPageRecord {
        id
        title        
      }
      ... on ServiceRecord {
        title
        subtitle
        slug
      }
      ... on ContactRecord {  
        id      
        title
        slug
      }
      ... on FutureFarmerServiceRecord {
        id
        title
        slug
      }
    }
    leftLamp {
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      responsiveImage(imgixParams: {auto: format}) {
        src
        width
        height
        alt
        base64
      }
      alt
    }
     rightLamp {
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      responsiveImage(imgixParams: {auto: format}) {
        src
        width
        height
        alt
        base64
      }
      alt
    }      
  }
  _site (locale: lang) {
    favicon: faviconMetaTags {
      attributes
      content
      tag
    }
    globalSeo {
      titleSuffix
      twitterAccount
      siteName
      facebookPageUrl
      fallbackSeo {
        title
        twitterCard
        description
      }
    }
    faviconMetaTags {
      tag
      content
      attributes
    }
    locales
  }
  event {
    _allTitleLocales {
      locale
    }
  }
  home {
    title
  }
  contact (locale: lang) {
    title
    slug
    subtitle
    author
    address
    description {
      value
    }
    openingHours {
      value
    }
    callUsAt
    map {
      title
      centeredAt {
        coordinates {
          latitude
          longitude
        }
      }
      zoomLevel
      mapPoints {
        title
        icon {
          url
        }
        coordinates {
          longitude
          latitude
        }
      }
    }
    metatags (locale: lang) {
      title
      description
      twitterCard
    }
    _seoMetaTags (locale: lang) {
      attributes
      content
      tag
    }
    seo: _seoMetaTags (locale: lang) {
      attributes
      content
      tag
    }
    noindex
    nofollow
  }
}`;

export const PREVIEW_LINK_QUERY = `{
  home (locale: lang) {
    title
  } 
  contact (locale: lang) {
    title
    slug
  }
  restaurantMenu (locale: lang) {
    slug
    title
  }
  dailyProposal(locale: lang) {
    slug
    title
  }
  allEvents (locale: lang) {
    title
    slug
  }
  allSpecialPromotions(locale:lang){
    title
    slug
  }
  allSpecialEvents(locale:lang){
    title
    slug
  }
  wiFiLanding (locale:lang){
    title
    slug
  }
  eventsPage(locale:lang) {
    title
  }
  futureFarmersPage(locale:lang) {
    title
    slug
  }
  reserveATableModel(locale:lang) {
    title
  }
}`;
