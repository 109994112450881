const isServer = typeof window === "undefined";

/**
 * Used for return selected language data
 * @returns
 */
// const getSelectedLangData = (locale) => {
//   if (!isServer) {
//     let lang = locale || "en";
//     var match = document.cookie.match(
//       new RegExp("(^| )" + "NEXT_LOCALE" + "=([^;]+)")
//     );
//     if (match?.length > 0) {
//       lang = match[2];
//     }
//     // else {
//     //   set_cookie(lang);
//     // }
//     return lang;
//   } else {
//     return "en";
//   }
// };

/**
 * Used to return widget selected language
 * @returns
 */
const getIframeLocale = (lang) => {
  switch (lang) {
    case "it":
      return "it_IT";

    case "fr":
      return "fr_FR";

    case "de":
      return "de_DE";

    default:
      return "en_GB";
  }
};

/**
 * Set given value in cookie
 * @param {*} value
 */
const set_cookie = (value) => {
  if (!isServer) {
    document.cookie = `NEXT_LOCALE=${value}; max-age=31536000; path=/`;
  }
};

/**
 * Used for return active menu list
 * @returns
 */
const getAllMenuList = () => {
  const allMenuList = [
    {
      name: "Home",
      value: "Home",
      link: "/",
      slug: "home",
    },
    {
      name: "Bistrot",
      value: "Bistrot",
      link: "/bistrot",
      slug: "bistrot",
    },
    {
      name: "Mixology lounge",
      value: "Mixology Lounge",
      link: "/mixology-lounge",
      slug: "mixology-lounge",
    },
    {
      name: "Hotel",
      value: "Hotel",
      link: "/hotel",
      slug: "hotel",
    },
    {
      name: "Contact",
      value: "Contact",
      link: "/contact",
      slug: "contact",
    },
  ];

  return allMenuList;
};

/**
 * Used for return error messaged based on selected languages
 * @returns
 */
const getErrorMessages = (locale) => {
  if (locale === "it") {
    let errors = {
      required: "Questo campo è obbligatorio",
      email: "Controlla che il tuo indirizzo sia corretto",
      date_of_birth: "Inserisci la data nel formato GG-MM-AAAA",
      dobText: "Devi avere almeno 18 anni",
      marketing: "Per procedere devi accettare le condizioni definite nella nostra Privacy Policy",
      emailText:
        "Inserendo il tuo indirizzo email accetti di ricevere le nostre offerte in base a quanto stabilito dalla nostra Privacy Policy",
      checkbox: "Iscriviti anche alla newsletter",
    };
    return errors;
  } else {
    let errors = {
      required: "This field is mandatory",
      email: "Please check if your email address is correct",
      date_of_birth: "Please enter the date in DD-MM-YYYY format",
      dobText: "You must be 18 years old or above.",
      marketing: "You have to agree to the terms outlined in our Privacy Policy to proceed",
      emailText:
        "By entering your email address, you agree to receive our marketing offers in accordance with our Privacy Policy",
      checkbox: "Sign up for newsletter too",
    };
    return errors;
  }
};

/**
 * Get active server URL
 * @returns
 */
const getServerURL = () => {
  let hostname = "http://localhost:3000";
  if (typeof window !== "undefined") {
    hostname = window.location.origin;
  }
  return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
};

const getLangList = () => {
  const langList = [
    {
      name: "EN",
      value: "en",
    },
    {
      name: "IT",
      value: "it",
    },
  ];
  return langList;
};

const getWindowWidth = () => {
  let width = 1400;
  if (typeof window !== "undefined") {
    width = window.innerWidth;
  }
  return width;
};

/**
 * Middleware for call CMS API using next API routes
 * @param {*} query
 * @param {*} preview
 * @returns
 */
const fetcher = async (query, preview, variables = {}, isURL = false) => {
  const res = await fetch(`${!isURL ? getServerURL() : ""}/api/graphql`, {
    // const res = await fetch("http://localhost:3000/api/graphql", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ query, preview, variables }),
  });

  return res.json();
};

/**
 * Used for getting image ration and used that in graphQL query
 * @returns
 */
const getImageRatio = () => {
  const width = getWindowWidth();
  let imageRatioList = {};
  if (width > 1200) {
    imageRatioList.homePage = {
      topCarousel: "16x9",
      boxImage: "1x1",
    };
    imageRatioList.templatePage = {
      topCarousel: "16x9",
      eventBgImag: "16x9",
    };
    imageRatioList.landingPage = {
      specialPromotion: "2x3",
      specialOfTheDay: "1x1",
    };
  } else if (width > 768) {
    imageRatioList.homePage = {
      topCarousel: "16x9",
      boxImage: "1x1",
    };
    imageRatioList.templatePage = {
      topCarousel: "16x9",
      eventBgImag: "16x9",
    };
    imageRatioList.landingPage = {
      specialPromotion: "4x3",
      specialOfTheDay: "4x3",
    };
  } else if (width > 576) {
    imageRatioList.homePage = {
      topCarousel: "1x1",
      boxImage: "16x9",
    };
    imageRatioList.templatePage = {
      topCarousel: "1x1",
      eventBgImag: "1x1",
    };
    imageRatioList.landingPage = {
      specialPromotion: "2x3",
      specialOfTheDay: "4x3",
    };
  } else if (width < 576) {
    imageRatioList.homePage = {
      topCarousel: "2x3",
      boxImage: "1x1",
    };
    imageRatioList.templatePage = {
      topCarousel: "2x3",
      eventBgImag: "2x3",
    };
    imageRatioList.landingPage = {
      specialPromotion: "2x3",
      specialOfTheDay: "4x3",
    };
  }
  return imageRatioList;
};

/**
 * This function is for check  dynamic slug and redirect on frontend
 * @returns
 */
const getPreviewRedirectURL = (slug, allSlugData) => {
  let url = "/";
  if (allSlugData?.home && slug === "home") {
    url = "/";
  }
  if (allSlugData?.contact && slug === allSlugData?.contact?.slug) {
    url = "/contact";
  }
  if (allSlugData?.futureFarmersPage && slug === allSlugData?.futureFarmersPage?.slug) {
    url = `/${allSlugData?.futureFarmersPage?.slug}`;
  }
  if (allSlugData?.restaurantMenu && slug === allSlugData?.restaurantMenu?.slug) {
    url = `/eat/${allSlugData?.restaurantMenu?.slug}`;
  }
  if (allSlugData?.dailyProposal && slug === allSlugData?.dailyProposal?.slug) {
    url = `/eat/${allSlugData?.dailyProposal?.slug}`;
  }
  allSlugData?.allTemplate2s?.filter((item) => {
    item?.slug === slug ? (url = `/food-drinks/${item?.slug}`) : null;
  });
  allSlugData?.allEvents?.filter((item) => {
    item?.slug === slug ? (url = `/landing/${item?.slug}`) : null;
  });
  allSlugData?.allSpecialEvents?.filter((item) => {
    item?.slug === slug ? (url = `/landing/${item?.slug}`) : null;
  });
  allSlugData?.allSpecialPromotions?.filter((item) => {
    item?.slug === slug ? (url = `/landing/${item?.slug}`) : null;
  });
  if (allSlugData?.specialPromotion && slug === allSlugData?.specialPromotion?.slug) {
    url = `/landing/${allSlugData?.specialPromotion?.slug}`;
  }
  if (allSlugData?.wiFiLanding && slug === allSlugData?.wiFiLanding?.slug) {
    url = `/landing/${allSlugData?.wiFiLanding?.slug}`;
  }
  if (allSlugData?.eventsPage && slug === "events") {
    url = "/events";
  }
  if (allSlugData?.reserveATableModel && slug === "booknow") {
    url = "/booknow";
  }

  return url;
};

const getLandingPageData = (slug, eventResponse, locale) => {
  let finalSlug = slug;
  let pageType = "404";
  let localesList = [];
  for (const list in eventResponse) {
    if (Array.isArray(eventResponse[list])) {
      eventResponse[list]?.filter((element) => {
        if (element?.slug === slug) {
          if (list === "allEvents") {
            pageType = "event";
          }
          if (list === "allSpecialPromotions") {
            pageType = "special-promotion";
          }
          if (list === "allSpecialEvents") {
            pageType = "special-event";
          }
          localesList = element?._allSlugLocales;
          let finalData = element?._allSlugLocales?.find((item) => item?.locale === locale);
          finalSlug = finalData?.value || element?.slug;
        }
      });
    } else {
      if (eventResponse[list]?.slug === slug) {
        localesList = eventResponse[list]?._allSlugLocales;
        if (list === "wiFiLanding") {
          pageType = "wifi-landing";
        }
        if (list === "specialEventReservation") {
          pageType = "special-reservation";
        }
        let finalData = eventResponse[list]?._allSlugLocales?.find(
          (item) => item?.locale === locale,
        );
        finalSlug = finalData?.value || eventResponse[list]?.slug;
      }
    }
  }

  return { pageType, finalSlug, localesList };
};

// Used to initialize owl carousel with the first slide (if video) playing
const handleCarouselInitialize = (event) => {
  const carousel = event.target;

  // If the first slide is a video select and play it
  const firstVideo = carousel.querySelector(".owl-item.active video");
  if (firstVideo) {
    firstVideo.play();
  }
};

// Used on the owl carousel translate event,
// to pause a video when moving away from the video slide, and/or
// play the video in the next slide
const handleCarouselTranslate = (event) => {
  if (event.namespace) {
    const carousel = event.target;
    const currentVideo = carousel.querySelector(".owl-item.active video");
    if (currentVideo) {
      currentVideo.pause(); // Pause the video in the current slide
    }

    // If the next slide is a video select and play it
    const nextVideo = carousel
      .querySelectorAll(".owl-item")
      [event.item.index].querySelector("video");
    if (nextVideo) {
      nextVideo.play();
    }
  }
};

/* Remove trailing slash on canonical url */
const removeTrailingSlash = (url) => {
  return url.endsWith("/") ? url.slice(0, -1) : url;
};

export const instagramMediaFilter = (posts = []) => {
  const POST_MEDIA_TYPES = {
    IMAGE: "IMAGE",
    VIDEO: "VIDEO",
    CAROUSEL_ALBUM: "CAROUSEL_ALBUM",
  };

  if (!posts || posts?.length === 0) return [];

  return posts?.filter((post) => {
    if (post?.children?.length > 0) {
      post.children = instagramMediaFilter(post.children);
    }

    if (post.mediaType === POST_MEDIA_TYPES.CAROUSEL_ALBUM && post?.children?.length === 0) {
      return false;
    }

    if (
      (post.mediaType === POST_MEDIA_TYPES.CAROUSEL_ALBUM && post?.mediaUrl) ||
      (post.mediaType === POST_MEDIA_TYPES.IMAGE && post?.mediaUrl) ||
      (post.mediaType === POST_MEDIA_TYPES.VIDEO && (post?.mediaUrl || post?.thumbnailUrl))
    ) {
      return true;
    }

    return false;
  });
};

export {
  // getSelectedLangData,
  getIframeLocale,
  getAllMenuList,
  set_cookie,
  getErrorMessages,
  getServerURL,
  getLangList,
  getWindowWidth,
  fetcher,
  getImageRatio,
  getPreviewRedirectURL,
  getLandingPageData,
  handleCarouselInitialize,
  handleCarouselTranslate,
  removeTrailingSlash,
};
