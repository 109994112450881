import {
  START_FETCH_LANDING_PAGE_DATA,
  SUCCESS_FETCH_LANDING_PAGE_DATA,
  ERROR_FETCH_LANDING_PAGE_DATA,
} from "../actions/landingPageActionsTypes";

const initialState = {
  listIsLoading: false,
  landingPageData: [],
  errorFetchingLandingPageData: "",
};

const LandingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_LANDING_PAGE_DATA:
      return {
        ...state,
        listIsLoading: true,
      };
    case SUCCESS_FETCH_LANDING_PAGE_DATA:
      return {
        ...state,
        listIsLoading: false,
        landingPageData: action.landingPageData,
        errorFetchingLandingPageData: "",
      };
    case ERROR_FETCH_LANDING_PAGE_DATA:
      return {
        ...state,
        listIsLoading: false,
        errorFetchingLandingPageData: action.error,
      };
    default:
      return state;
  }
};
export default LandingPageReducer;
