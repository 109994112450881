import {
  START_FETCH_RESTAURANT_MENU_DATA,
  SUCCESS_FETCH_RESTAURANT_MENU_DATA,
  ERROR_FETCH_RESTAURANT_MENU_DATA,
} from "../actions/restaurantMenuActionsTypes";

const initialState = {
  isLoading: false,
  restaurantMenuData: [],
  errorFetchingTemplatesData: "",
};

const RestaurantMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_RESTAURANT_MENU_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_RESTAURANT_MENU_DATA:
      return {
        ...state,
        isLoading: false,
        restaurantMenuData: action.restaurantData,
        errorFetchingTemplatesData: "",
      };
    case ERROR_FETCH_RESTAURANT_MENU_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingTemplatesData: action.error,
      };
    default:
      return state;
  }
};
export default RestaurantMenuReducer;
